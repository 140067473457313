<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <!--    <el-form-item label="Шкала" prop="parent_id">-->
    <!--      <el-select-->
    <!--        v-model="scale.parent_id"-->
    <!--      >-->
    <!--        <el-option-->
    <!--          v-for="template in scaleTemplates"-->
    <!--          :key="template.id"-->
    <!--          :label="template.name"-->
    <!--          :value="template.id"-->
    <!--        ></el-option>-->
    <!--      </el-select>-->
    <!--    </el-form-item>-->

    <el-form-item label="Уровень достижения цели">
      <el-row :gutter="40" style="margin-bottom: 15px; padding-left: 20px">
        <el-col :span="9">Цель выполнена полностью (100%)</el-col>
        <el-col :span="15">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="scale.pay_100"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="40" style="margin-bottom: 15px; padding-left: 20px">
        <el-col :span="9">Цель выполнена не полностью, незначительное отклонение (75%)</el-col>
        <el-col :span="15">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="scale.pay_75"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="40" style="margin-bottom: 15px; padding-left: 20px">
        <el-col :span="9">Цель выполнена не полностью, допустимые отклонения (50%)</el-col>
        <el-col :span="15">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="scale.pay_50"
          ></el-input>
        </el-col>
      </el-row>
    </el-form-item>

    <el-form-item label="Единица измерения" prop="unit">
      <el-input
        v-model="scale.unit"
      ></el-input>
    </el-form-item>

    <el-form-item label="Плановое значение" prop="plan">
      <el-input
        v-model="scale.plan"
      ></el-input>
    </el-form-item>

    <el-form-item label="Фактическое значение" prop="fact">
      <el-select
        v-model="scale.fact"
        style="width:100%"
      >
        <el-option
          v-for="scope in scopes"
          :key="scope.id"
          :label="scope.target"
          :value="scope.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Ответственный" prop="responsible">
      <el-input
        v-model="scale.responsible"
      ></el-input>
    </el-form-item>

  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import requestSender from "@/api/base/requestSender";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    });
    requestSender('get', 'scale/templates-list', {})
      .then(data => {
        // У нас может быть удаленный или залоченный темплейт. Нужно его тоже пушнуть в массив
        if (this.scale.parent) {
          if (!data.scales.design.find(t => t.id === this.scale.parent.id)) {
            data.scales.design.push({
              id: this.scale.parent.id,
              name: this.scale.parent.name,
            })
          }
        }
        this.scaleTemplates = data.scales.design;
        this.scale.parent_id = this.scaleTemplates[0].id;
        this.scopes = this.scaleTemplates[0].scopes;
      })
  },

  data() {
    return {
      scaleTemplates: [],
      scopes: [],

      scale: {
        parent: null,

        parent_id: null,
        unit: null,
        pay_100: null,
        pay_75: null,
        pay_50: null,
        plan: null,
        fact: null,
        responsible: null,
      },

      rules: {
        parent_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        unit: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        plan: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
      }

    }
  },

  methods: {}
}
</script>

<style>

</style>